@import "./sass/vendor-bootstrap";
@import "animate.css";
html {
  font-size: 62.5%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

label {
  font-size: 1.6rem;
}

input,
select,
textarea {
  border-radius: 4px;
  padding: 10px;
  height: 4.8rem;
  border: 1px solid #858585;
  font-size: 1.5rem;
  transition: box-shadow ease-in-out 100ms;
  width: 100%;

  &::placeholder {
  }

  &:focus {
    outline: 1px solid #01513a;
    box-shadow: 0 0 0 2px rgba(1, 130, 65, 0.5);
  }

  & .input-invalid {
    border: 1px solid red;
  }
}

textarea {
  max-width: 37rem;
  overflow-y: auto;
}

.owner {
  min-width: 8rem;
  max-width: 11rem;
  border-radius: 3rem;
  height: 2.5rem;
  font-weight: 500;
}

.pointer {
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
}

body {
  position: relative;
}

.page__name {
  font-size: 4rem;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  margin-bottom: 0;
}

.table__container {
  height: calc(100% - 16rem);
  overflow: auto;

  .pagination {
    border-bottom-left-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
  }
}

// styling the scroll bar
::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #01513a;
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  transition: background-color 0.5s ease;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #559180;
}

// end of  styling  sroll bar

select {
  // for the dropdown indicator
  appearance: none;
  background-image: url("./assets/images/arrow.svg");
  background-repeat: no-repeat;
  background-position: right 2rem center;
  background-size: 2rem;
}

button {
  color: var(--bs-body-color);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
