// bootstrap customization
// Warning: Order matters here. If you change the order, things might break.
$primary: hsl(163, 98%, 16%);
$secondary: hsl(204, 28%, 59%);

:root {
  --bs-body-color: hsl(0, 0%, 20%) !important;
}

@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/utilities";

$alt-dark: hsl(0, 0%, 20%);
$alt-light: hsl(0, 0%, 100%);
$toast-success: hsl(150, 26%, 80%);
$toast-error: hsl(353, 53%, 90%);
$toast-warning: hsl(46, 92%, 91%);
$toast-info: hsl(193, 83%, 91%);

$owned: hsl(152, 69%, 31%);
$rented: hsl(27, 98%, 54%);
$gray-300: hsl(0, 0%, 52%);
$hint-danger: hsl(0, 61%, 68.8%);

$custom-theme-colors: map-merge(
    $theme-colors,
    (
      "alt-dark": $alt-dark,
      "alt-light": $alt-light,
      "toast-success": $toast-success,
      "toast-error": $toast-error,
      "toast-warning": $toast-warning,
      "toast-info": $toast-info,
      "owned": $owned,
      "rented": $rented,
      "gray-300": $gray-300,
      "hint-danger": $hint-danger
    )
);

$theme-colors: map-merge($theme-colors, $custom-theme-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-text-colors: map-loop(
    $utilities-colors,
    rgba-css-var,
    "$key",
    "text"
);
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, "$key", "bg");

$utilities: (
  "w": (
    property: width,
    class: w,
    values: (
      25: 25%,
      35: 35%,
      50: 50%,
      75: 75%,
      100: 100%,
      auto: auto,
      60: 600px,
      80: 800px
    )
  )
);

@import "../../node_modules/bootstrap/scss/bootstrap.scss";
